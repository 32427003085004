import React from 'react';
import Footer from '../components/Footer';
import Layout from '../components/layout';
import Navigation from '../components/Navigation';
import PrortfolioAppList from '../components/PortfolioAppList';
import SEO from '../components/seo';
import MessageUs from '../components/MessageUs';
import PortfolioHeader from '../components/PortfolioHeader';
import { HeadProps, PageProps, graphql } from 'gatsby';
import { LocaleNode } from 'gatsby-plugin-react-i18next/dist/types';
import {
  Portfolio,
  PortfolioAppsType,
  PortfolioBlogType,
} from '../utils/portfolioTypes';
import CommentsUpdated from '../components/Comments/CommentsUpdated';
import BlogPostsList from '../components/BlogPostsList';

type DataProps = {
  allSanityApp: PortfolioAppsType;
  allSanityArticle: PortfolioBlogType;
  sanityPortfolio: Portfolio;
  locales: { edges: Array<{ node: LocaleNode }> };
};

const IndexPage = ({ data }: PageProps<DataProps>) => {
  const { allSanityApp, allSanityArticle, sanityPortfolio } = data;
  const {
    feedback,
    blog,
    title,
    subtitle,
    note,
    apps: selectedApps,
  } = sanityPortfolio;

  return (
    <Layout>
      <Navigation headerBackgroundColor="none" currentPage="portfolio" />
      <PortfolioHeader title={title} subtitle={subtitle} note={note} />
      <PrortfolioAppList
        apps={allSanityApp.nodes}
        selectedApps={selectedApps}
      />
      <BlogPostsList
        articles={allSanityArticle}
        selectedArticles={blog.articles}
        mainArticleDescription={blog.mainArticleDescription}
        title={blog.title}
      />
      <CommentsUpdated title={feedback.title} commentsOrder={feedback.items} />
      <MessageUs />
      <Footer noFooterCard={true} isLegacy={false} />
    </Layout>
  );
};

export const query = graphql`
  query CareersInfo($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allSanityApp(sort: { order: ASC, fields: order }) {
      nodes {
        title
        tags
        moto
        slug {
          current
        }
        serviceData {
          description
          image {
            asset {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
    allSanityArticle {
      nodes {
        slug {
          current
        }
        tags
        title
        _updatedAt
        headerImage {
          mainImage {
            asset {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
    sanityPortfolio {
      title
      subtitle
      note
      apps
      feedback {
        title
        items
      }
      blog {
        title
        mainArticleDescription
        articles {
          slug
          readTime
        }
      }
      seoData {
        seoTitle
        seoDescription
      }
    }
  }
`;

export const Head = ({ data }: HeadProps<DataProps>) => {
  const { sanityPortfolio } = data;

  return (
    <SEO
      title={sanityPortfolio.seoData.seoTitle}
      description={sanityPortfolio.seoData.seoDescription}
    />
  );
};

export default React.memo(IndexPage);
