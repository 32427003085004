import React, { FC, memo } from 'react';
import { PortfolioApp } from '../../utils/portfolioTypes';
import Text from '../Text';
import './style.scss';
import Category from '../Category';
import { GatsbyImage } from 'gatsby-plugin-image';
import Button from '../Button';
import classNames from 'classnames';
import Link from '../Link';

interface Props {
  app: PortfolioApp;
  contentJustify: 'left' | 'right';
}

const PortfolioAppItem: FC<Props> = ({
  app: { title, moto, tags, serviceData, slug },
  contentJustify,
}) => {
  return (
    <li
      className={classNames('portfolio__app-list__item', {
        'portfolio__app-list__item_right': contentJustify === 'right',
      })}
    >
      <Link
        href={`/product/${slug.current}/`}
        className={classNames('portfolio__app-list__item__text', {
          'portfolio__app-list__item__text_right': contentJustify === 'right',
        })}
      >
        <Text
          type="h3"
          weight="medium"
          className={classNames('portfolio__app-list__item__title h2', {
            'portfolio__app-list__item__title_right':
              contentJustify === 'right',
          })}
        >
          {title}
        </Text>
        <Text
          type="p"
          weight="bold"
          className={classNames('portfolio__app-list__item__subtitle h5', {
            'portfolio__app-list__item__subtitle_right':
              contentJustify === 'right',
          })}
        >
          {moto}
        </Text>
        <Text
          type="p"
          className={classNames(
            'portfolio__app-list__item__description p-updated',
            {
              'portfolio__app-list__item__description_right ':
                contentJustify === 'right',
            }
          )}
        >
          {serviceData.description}
        </Text>
      </Link>
      <Button
        size="regular"
        href={`/product/${slug.current}/`}
        rounded
        color="dark-grey"
        className={classNames('portfolio__app-list__item__button', {
          'portfolio__app-list__item__button_right': contentJustify === 'right',
        })}
      >
        <Text
          type="label"
          color="light"
          className="portfolio__app-list__item__button__text"
        >
          View case study
        </Text>
      </Button>
      <div
        className={classNames('portfolio__app-list__item__tags', {
          'portfolio__app-list__item__tags_right': contentJustify === 'right',
        })}
      >
        {tags.map((tag, index) => (
          <Category isWide style="light-grey" key={index}>
            {tag}
          </Category>
        ))}
      </div>

      <Link
        href={`/product/${slug.current}/`}
        className={classNames('portfolio__app-list__item__image', {
          'portfolio__app-list__item__image_right': contentJustify === 'right',
        })}
      >
        <GatsbyImage
          image={serviceData.image.asset.gatsbyImageData}
          alt="app"
          objectFit="cover"
        />
      </Link>
    </li>
  );
};

export default memo(PortfolioAppItem);
