import React, { FC, memo } from 'react';
import Text from '../Text';
import './style.scss';
import Button, { ButtonLinkType } from '../Button';

interface Props {
  title: string;
  subtitle: string;
  note: string;
}

const PortfolioHeader: FC<Props> = ({ title, subtitle, note }) => {
  return (
    <header className="portfolio__header">
      <div className="portfolio__header__wrapper">
        <Text
          type="h1"
          weight="medium"
          color="light"
          className="portfolio__header__title h2"
        >
          {title}
        </Text>
        <div className="portfolio__header__subtitle">
          <Text
            type="p"
            weight="bold"
            color="light"
            className="portfolio__header__text"
          >
            {subtitle}
          </Text>
          <Text type="p" color="dark-grey" className="portfolio__header__text">
            {note}
          </Text>
        </div>
        <Button
          size="big"
          href={'#message-us'}
          rounded
          color="secondary"
          linkType={ButtonLinkType.ANCHOR}
          className="portfolio__header__action__btn"
        >
          <Text type="label" className="portfolio__header__action__btn__text">
            Contact us
          </Text>
        </Button>
      </div>
    </header>
  );
};

export default memo(PortfolioHeader);
