import './style.scss';
import { PortfolioApp } from '../../utils/portfolioTypes';
import PortfolioAppItem from '../PortfolioAppItem';
import React, { memo, useMemo } from 'react';

interface Props {
  apps: PortfolioApp[];
  selectedApps: string[];
}

const PrortfolioAppList: React.FC<Props> = ({ apps, selectedApps }) => {
  const filtederApps = useMemo(
    () =>
      apps.filter(
        (item) => selectedApps.includes(item.title) && item.serviceData
      ),
    [apps, selectedApps]
  );

  return (
    <ul className="portfolio__app-list">
      {filtederApps.map((app, index) => (
        <PortfolioAppItem
          app={app}
          key={app.title}
          contentJustify={index % 2 === 0 ? 'left' : 'right'}
        />
      ))}
    </ul>
  );
};

export default memo(PrortfolioAppList);
